import React from "react"
import { css } from "@emotion/core"
import { GitHub, Linkedin, Malt } from './Socials'
import Container from './Container'

const Footer = () => (
    <footer>
        <Container>
            <div
                css={css`
                    display:flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 5em;
                    margin-bottom: 5em;

                    @media (max-width: 500px) {
                        display:block;
                        text-align:center;
                    }
                `}
            >
                <div
                    css={css`
                        font-family:'GTWalsheim';
                        font-size: 1rem;
                        opacity: 0.8;

                        @media (max-width: 500px) {
                            margin-bottom:2em;
                        }
                    `}
                >
                    Guillaume Mourier © {new Date().getFullYear()}  
                </div>
                <div>
                    <Malt/>
                    <GitHub 
                        margin="0rem 0rem 0rem 0.25rem"
                    />
                    <Linkedin
                        margin="0rem 0rem 0rem 0.25rem"
                    />
                </div>
            </div>
        </Container>
    </footer>
)

export default Footer
