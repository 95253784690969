import React from "react"
import { css } from "@emotion/core"
import Nav from "./Nav"
import Container from "./Container"

const Header = () => (
    <header>
        <Container>
            <div
                css={css`
                    display:flex;
                    align-items:center;
                    justify-content:space-between;
                    padding-top:2rem;
                    padding-bottom: 2rem;

                    @media (max-width: 960px) {
                        display:block;
                    }
                `}
            >
                <div
                    css={css`
                        font-family: 'Anja';
                        color:#1D3557;
                        font-size:2rem;
                        text-decoration:underline;
                        text-decoration-color:#A8DADC;
                        
                        @media (max-width: 960px) {
                            text-align:center;
                        }
                    `}
                >
                    gm
                </div>
                <Nav />
            </div>
        </Container>
    </header>
)

export default Header
