import React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ children }) => {
    return (
        <>
            <Global
                styles={css`
                    @font-face {
                        font-family: 'Anja';
                        src: local('Anja Eliane Nornal'), url(${require('../fonts/AnjaEliane.woff')}) format('woff');
                        font-style: normal;
                        font-weight: normal;
                    }
                    @font-face {
                        font-family: 'FreightTextBook';
                        src: url(${require('../fonts/FreightTextBook.woff2')}) format('woff2'),
                            url(${require('../fonts/FreightTextBook.woff')}) format('woff');
                        font-weight: normal;
                        font-style: normal;
                    }
                    @font-face {
                        font-family: 'GTWalsheim';
                        src: url(${require('../fonts/GTWalsheimPro-Bold.woff')}) format('woff');
                        font-weight: normal;
                        font-style: normal;
                    }
                    body {
                        margin: 0 auto;
                        font-size: 16px;
                        background-color: #F4F7FA;
                        color: #334259;
                    }
                    a {
                        text-decoration:none;
                    }
                    h1,h2,h3,h4,h5,h6 {
                        font-family: 'GTWalsheim';
                        padding: 0px;
                        margin:0px;
                    }
                    fieldset {
                        border:none;
                        padding:0;
                        margin:0;
                    }
                `}
            />
            <Header/>
            <main>
                {children}
            </main>
            <Footer/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
