import React from 'react'
import { css } from "@emotion/core"

const Container = props => {
    return (
        <div
            css={css`
                max-width: 1150px;
                margin: 0em auto;
                @media (max-width: 1150px) {
                   max-width:960px;
                }
                @media (max-width: 960px) {
                    padding-left:1rem;
                    padding-right: 1rem;
                }
            `}
        >
            {props.children}
        </div>
    )
}

export default Container