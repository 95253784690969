import { Link } from "gatsby"
import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const StyledLi = styled.li`
    display:inline-block;
    font-family: 'GTWalsheim';
    font-size:1rem;
    margin-left: 1rem;
    color: #1D3557;

    @media (max-width: 960px) {
        display:block;
        margin-bottom:1rem;
        margin-left:0px;
    }
`
const StyledLink=styled(Link)`
    color: #1D3557;
    &:hover{
        border-bottom:3px solid #A8DADC;
    }
    &.active{
        border-bottom:3px solid #A8DADC;
    }
`
const StyledButton=styled.a`
    color: #1D3557;
    border: 2px solid #1D3557;
    padding:8px;
`
const Nav = () => (
    <nav>
        <div
            css={css`
                list-style: none;
                margin-left: 0px;
                padding-inline-start:0px;
                @media (max-width: 960px) {
                    margin-top:50px;
                    text-align:center;
                }
            `}
        >
            <StyledLi>
                <StyledLink to="/" activeClassName="active">Présentation</StyledLink>
            </StyledLi>
            <StyledLi>
                <StyledLink to="/contact" activeClassName="active">Contact</StyledLink>
            </StyledLi>
            <StyledLi css={css`
                @media (max-width: 960px) {
                    margin-top:22px;
                }
            `}>
                <StyledButton href="https://www.malt.fr/profile/guillaumemourier" target="_blank" rel="noopener noreferrer">
                    <img 
                    src={require('../images/malt_picto_color.svg')}
                    alt="Pictogramme Malt"
                    height="20px"
                    width="20px" 
                    css={css`
                        display: inline-block;
                        vertical-align:middle;
                        margin-right:4px;
                        margin-top:-2px;
                    `}
                    />
                    Mon profil Malt
                </StyledButton>
            </StyledLi>
        </div>
    </nav>
)

export default Nav