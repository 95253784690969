import React from 'react'
import { css } from "@emotion/core"

export const GitHub = (props) => {
    return (
        <a
            css={css`
                text-decoration:none;
                color: #334259;
                opacity: 0.8;
                margin:${props.margin ? props.margin : '0rem'}
            `}
            href="https://github.com/gmourier"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit my GitHub"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M11.2941176,0.279031142 C5.08235294,0.279031142 0,5.3015917 0,11.4402768 C0,16.3233218 3.24705882,20.5087889 7.76470588,22.0434602 C8.32941176,22.1829758 8.47058824,21.7644291 8.47058824,21.4853979 C8.47058824,21.2063668 8.47058824,20.5087889 8.47058824,19.5321799 C5.36470588,20.2297578 4.65882353,18.1370242 4.65882353,18.1370242 C4.09411765,16.8813841 3.38823529,16.4628374 3.38823529,16.4628374 C2.4,15.7652595 3.52941176,15.7652595 3.52941176,15.7652595 C4.65882353,15.9047751 5.22352941,16.8813841 5.22352941,16.8813841 C6.21176471,18.6950865 7.90588235,18.1370242 8.47058824,17.8579931 C8.61176471,17.1604152 8.89411765,16.6023529 9.17647059,16.3233218 C6.63529412,16.0442907 4.09411765,15.0676817 4.09411765,10.742699 C4.09411765,9.48705882 4.51764706,8.51044983 5.22352941,7.81287197 C5.08235294,7.53384083 4.65882353,6.41771626 5.36470588,4.88304498 C5.36470588,4.88304498 6.35294118,4.60401384 8.47058824,5.99916955 C9.31764706,5.72013841 10.3058824,5.58062284 11.2941176,5.58062284 C12.2823529,5.58062284 13.2705882,5.72013841 14.1176471,5.99916955 C16.2352941,4.60401384 17.2235294,4.88304498 17.2235294,4.88304498 C17.7882353,6.41771626 17.5058824,7.53384083 17.3647059,7.81287197 C18.0705882,8.6499654 18.4941176,9.62657439 18.4941176,10.742699 C18.4941176,15.0676817 15.8117647,15.9047751 13.2705882,16.1838062 C13.6941176,16.7418685 14.1176471,17.4394464 14.1176471,18.4160554 C14.1176471,19.9507266 14.1176471,21.0668512 14.1176471,21.4853979 C14.1176471,21.7644291 14.2588235,22.1829758 14.9647059,22.0434602 C19.4823529,20.5087889 22.7294118,16.3233218 22.7294118,11.4402768 C22.5882353,5.3015917 17.5058824,0.279031142 11.2941176,0.279031142 Z"
                />
            </svg>
        </a>
    )
}

export const Linkedin = (props) => {
    return (
        <a
            css={css`
                text-decoration:none;
                color: #334259;
                opacity: 0.8;
                margin:${props.margin ? props.margin : '0rem'}
            `}
            href="https://www.linkedin.com/in/gmourier"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Get in touch via Linkedin"
        >
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="25" 
                height="25" 
                viewBox="0 0 25 25"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 8c0 .557-.447 1.008-1 1.008s-1-.45-1-1.008c0-.557.447-1.008 1-1.008s1 .452 1 1.008zm0 2h-2v6h2v-6zm3 0h-2v6h2v-2.861c0-1.722 2.002-1.881 2.002 0v2.861h1.998v-3.359c0-3.284-3.128-3.164-4-1.548v-1.093z"
                />
            </svg>
        </a>
    )
}

export const Malt = (props) => {
    return (
        <a
            css={css`
                text-decoration:none;
                color: #334259;
                opacity: 0.8;
                margin:${props.margin ? props.margin : '0rem'}
            `}
            href="https://www.malt.fr/profile/guillaumemourier"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Get in touch via Malt"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg" 
                width="25" 
                height="25" 
                viewBox="0 0 100 100"
            >
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M34.6,79c0,7.8,5.1,12.3,11.5,12.3c0,0,0,0,0,0c6.3,0,11.5-6,11.6-12.3L46,67.3L34.6,79z M30.5,12.8
                    c-2.1-2.2-5-3.5-8.1-3.5c-3.1-0.1-6,1.1-8.2,3.2c-4.6,4.4-4.7,11.7-0.4,16.3l1.7,1.8l24.3,0.1l3.9-3.9L30.5,12.8z M78.4,62.7
                    l-1.7-1.8l-24.3-0.1l-3.9,3.9l13.3,13.9c4.4,4.6,11.7,4.7,16.3,0.4C82.6,74.6,82.7,67.3,78.4,62.7 M79.2,34.3L55.9,57.3l24.7,0.1
                    h0.1c3.1,0,5.9-1.2,8.1-3.3c2.2-2.1,3.4-5.1,3.4-8.1C92.2,39.6,87.2,34.2,79.2,34.3 M11.6,34L11.6,34c-3.1,0-6,1.2-8.2,3.3
                    C1.2,39.5,0,42.5,0,45.5c0,3.1,0.6,11.6,13.2,11.6l23.1-22.9C36.3,34.2,11.6,34,11.6,34z M78.9,13.2c-4.5-4.5-11.8-4.5-16.3-0.1
                    L13.4,61.9c-4.5,4.5-4.5,11.8-0.1,16.3c4.5,4.5,11.8,4.5,16.3,0.1l49.1-48.7C83.3,25,83.3,17.7,78.9,13.2 M58.6,12.3
                    C58.6,4.5,53.5,0,47.1,0c0,0,0,0,0,0c-6.3,0-11.5,6-11.6,12.3L47.1,24C47.1,24,58.6,12.3,58.6,12.3z"
                />
            </svg>
        </a>
    )
}